import * as Sentry from '@sentry/sveltekit';

import { ENV, SENTRY_DSN } from '$/env';

export async function init() {
	Sentry.init({
		environment: ENV,
		dsn: SENTRY_DSN,
		integrations: [Sentry.extraErrorDataIntegration({ depth: 10 })],
	});
}

export async function handleError({ error, event, status, message }) {
	const errorId = crypto.randomUUID();

	Sentry.captureException(error, {
		extra: { event, errorId, status },
	});

	return {
		message,
	};
}
